import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { injectIntl } from "gatsby-plugin-intl"
import NavBar from "../components/navbar"
import SEO from "../components/SEO/index"
import Slick from "../components/Slick"
import { Section } from "../components/CompoundComponents/Section"
import { Columns, Column } from "../components/CompoundComponents/Columns"
import {
  Card,
  CardContent,
  CardContentTitle,
} from "../components/CompoundComponents/Card"
import Footer from "../components/Footer/index"

const LuxuryVipPage = ({ data, intl }) => {
  return (
    <>
      <SEO
        title="Ниҳол саломатлик сиҳатгоҳи, Санаторий Нихол, Санаторий Учкоргон, Санаторий Узбекистан, Санаторий Наманган, Санаторий Андижан, Санаторий Фергана, Санаторий Ташкент, Санаторий Киргизия, Отдых санаторий Узбекистан, Санаторий Учкурган"
        description="Один из лучших санаториев Узбекистана - Санаторий Нихол, Санаторий Учкоргон, Санаторий Узбекистан, Санаторий Наманган, Санаторий Андижан, Санаторий Фергана, Санаторий Ташкент, Санаторий Киргизия, Отдых санаторий Узбекистан, Санаторий Учкурган, Ниҳол саломатлик сиҳатгоҳи"
      />
      <NavBar />
      <Section>
        <Columns>
          <Column is8>
            <Slick slideToShow={1} slideToScroll={1} speed={3000}>
              {data.luxury.edges.map(item => (
                <Img
                  fluid={item.node.featuredImage.childImageSharp.fluid}
                  alt={item.node.id}
                />
              ))}
            </Slick>
          </Column>
          <Column is4>
            <Card>
              <CardContent>
                <CardContentTitle>
                  {intl.formatMessage({
                    id: `index.rooms.item-5.title`,
                  })}
                </CardContentTitle>
              </CardContent>
              <footer className="card-footer">
                <span className="card-footer-item is-uppercase is-size-7 has-text-weight-bold">
                  {intl.formatMessage({
                    id: `index.rooms.item-5.room`,
                  })}
                </span>
                <span className="card-footer-item is-uppercase is-size-7 has-text-weight-bold">
                  {intl.formatMessage({
                    id: `index.rooms.item-5.place`,
                  })}
                </span>
              </footer>
              <footer className="card-footer has-text-centered">
                <span className="card-footer-item is-uppercase is-size-7 has-text-weight-bold">
                  {intl.formatMessage({
                    id: `index.rooms.item-5.price`,
                  })}
                </span>
              </footer>
              <footer className="card-footer">
                <Link
                  className="has-text-primary card-footer-item is-uppercase is-size-7 has-text-weight-bold"
                  to="/contact"
                >
                  {intl.formatMessage({
                    id: `header.nav.item-5`,
                  })}
                </Link>
              </footer>
            </Card>
          </Column>
        </Columns>
      </Section>
      <Footer data={data.section.edges[0]} />
    </>
  )
}

export const LuxuryVipPageQuery = graphql`
  query {
    luxury: allLuxuryvipYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    section: allProductsYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default injectIntl(LuxuryVipPage)
